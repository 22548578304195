<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :filterFields="filterFields"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('AccountEmployeeCreate')"
    @edit="(id) => onEdit('AccountEmployeeEdit', id)"
  >
    <template v-slot:departmentId="{ row }">
      {{
        row.departmentId &&
        departments.data.length &&
        departments.data.find((item) => item.id === row.departmentId)
          ? departments.data.find((item) => item.id === row.departmentId).name
          : ''
      }}
    </template>
  </sm-editable-list>
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'AccountEmployees',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'AccountEmployees',
      tableCaption: 'Список сотрудников',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Список сотрудников',
        },
      ],
      filterFields: [
        {
          text: 'Поле для отбора только сотрудников',
          alias: 'isCustomer',
        },
        {
          text: 'ФИО',
          alias: 'fio',
        },
        {
          text: 'Логин',
          alias: 'login',
        },
        {
          text: 'Пароль',
          alias: 'password',
        },
        {
          text: 'Почта',
          alias: 'mail',
        },
        {
          text: 'Закрытие заявок',
          alias: 'rightCloseRequest',
        },
        {
          text: 'Изменение часов по заявке',
          alias: 'rightUpdTime',
        },
        {
          text: 'Оповещение чата',
          alias: 'sendNotification',
        },
        {
          text: 'Полный доступ в ЛК',
          alias: 'rightCustomer',
        },
        {
          text: 'КСП прочитан',
          alias: 'kspReaded',
        },
        {
          text: 'КСП непрочитан',
          alias: 'kspUnreaded',
        },
        {
          text: 'Телефония - имя',
          alias: 'voipUserName',
        },
        {
          text: 'Телефония - пароль',
          alias: 'voipPassword',
        },
        {
          text: 'Телефония - хост',
          alias: 'voipHost',
        },
        {
          text: 'Телефония - порт',
          alias: 'voipPort',
        },
        {
          text: 'Не оповещать о новых заявках',
          alias: 'dontNotifyNewReq',
        },
        {
          text: 'Добавить к времени по UTC',
          alias: 'addHours',
        },
        {
          text: 'Доступ на сайт по IP',
          alias: 'accessIp',
        },
        {
          text: 'Может редактировать данные по ГИС ЖКХ',
          alias: 'isHasGisRights',
        },
        {
          text: 'Архивный сотрудник',
          alias: 'isArchive',
        },
        {
          text: 'Является пулом',
          alias: 'isPool',
        },
        {
          text: 'ID пула',
          alias: 'poolId',
        },
      ],
      tableHeaders: [
        {
          text: 'Поле для отбора только сотрудников',
          alias: 'isCustomer',
          order: 'isCustomer',
        },
        { text: 'ФИО', alias: 'fio', order: 'fio' },
        { text: 'Логин', alias: 'login', order: 'login' },
        { text: 'Пароль', alias: 'password', order: 'password' },
        { text: 'Почта', alias: 'mail', order: 'mail' },
        {
          text: 'Закрытие заявок',
          alias: 'rightCloseRequest',
          order: 'rightCloseRequest',
        },
        {
          text: 'Изменение часов по заявке',
          alias: 'rightUpdTime',
          order: 'rightUpdTime',
        },
        {
          text: 'Оповещение чата',
          alias: 'sendNotification',
          order: 'sendNotification',
        },
        {
          text: 'Полный доступ в ЛК',
          alias: 'rightCustomer',
          order: 'rightCustomer',
        },
        { text: 'КСП прочитан', alias: 'kspReaded', order: 'kspReaded' },
        { text: 'КСП непрочитан', alias: 'kspUnreaded', order: 'kspUnreaded' },
        {
          text: 'Телефония - имя',
          alias: 'voipUserName',
          order: 'voipUserName',
        },
        {
          text: 'Телефония - пароль',
          alias: 'voipPassword',
          order: 'voipPassword',
        },
        { text: 'Телефония - хост', alias: 'voipHost', order: 'voipHost' },
        { text: 'Телефония - порт', alias: 'voipPort', order: 'voipPort' },
        {
          text: 'Не оповещать о новых заявках',
          alias: 'dontNotifyNewReq',
          order: 'dontNotifyNewReq',
        },
        {
          text: 'Добавить к времени по UTC',
          alias: 'addHours',
          order: 'addHours',
        },
        { text: 'Доступ на сайт по IP', alias: 'accessIp', order: 'accessIp' },
        {
          text: 'Может редактировать данные по ГИС ЖКХ',
          alias: 'isHasGisRights',
          order: 'isHasGisRights',
        },
        { text: 'Архивный сотрудник', alias: 'isArchive', order: 'isArchive' },
        { text: 'Является пулом', alias: 'isPool', order: 'isPool' },
        { text: 'ID пула', alias: 'poolId', order: 'poolId' },
        { text: 'Подразделение', alias: 'department', order: 'department' },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },

  computed: {
    ...mapState({
      departments: (state) => state.common.departments,
    }),
  },

  created() {
    this.getCommonList({ name: 'Departments' });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
